// @flow

export const SPECIALTIES = {
  MAIN_SPECIALTIES: {
    LOADING: 'MAIN_SPECIALTIES_LOADING',
    SUCCEEDED: 'MAIN_SPECIALTIES_SUCCEEDED',
    FAILED: 'MAIN_SPECIALTIES_FAILED'
  },
  SUB_SPECIALTIES: {
    LOADING: 'SUB_SPECIALTIES_LOADING',
    SUCCEEDED: 'SUB_SPECIALTIES_SUCCEEDED',
    FAILED: 'SUB_SPECIALTIES_FAILED'
  }
};

export const loadMainSpecialties = (culture: string) => ({
  type: SPECIALTIES.MAIN_SPECIALTIES.LOADING,
  culture
});

export const loadSubSpecialties = (mainSpecialty: string) => ({
  type: SPECIALTIES.SUB_SPECIALTIES.LOADING,
  mainSpecialty
});
