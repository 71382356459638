import { LANGUAGE } from '../actions/language';

const initialState = {
  languages: [],
  loaded: false
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case LANGUAGE.LOAD:
      return {
        ...state,
        loaded: false
      };
    case LANGUAGE.LOAD_DONE:
      return {
        ...state,
        languages: payload.languages,
        loaded: true
      };
    case LANGUAGE.LOAD_FAIL:
      return {
        ...state,
        loaded: false
      };
    default:
      return state;
  }
};
