import { COUNTRY } from '../actions/country';

const initialState = {
  Countries: [],
  CountriesList: [],
  countriesCodes: new Map(),
  countriesMap: new Map(),
  countriesCodesMap: new Map(),
  countriesDialCodes: new Map(),
  loaded: false,
  loading: false
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case COUNTRY.LOAD:
      return Object.assign({}, state, { loaded: false }, { loading: true });
    case COUNTRY.LOAD_DONE: {
      const countriesMap = new Map();
      action.countries.forEach(country => countriesMap.set(country.CountryId, country));

      return {
        ...state,
        Countries: [...action.countries],
        countriesMap,
        loaded: true,
        loading: false
      };
    }
    case COUNTRY.LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    case COUNTRY.LOAD_LIST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case COUNTRY.LOAD_LIST_DONE: {
      const countriesCodes = new Map();
      const countriesCodesMap = new Map();
      const countriesDialCodes = new Map();

      action.countries.forEach(country => {
        countriesCodes.set(country.IsoCode, country.DialCode);
        countriesCodesMap.set(country.IsoCode, country);
        countriesDialCodes.set(country.DialCode, country.IsoCode);
      });

      return {
        ...state,
        CountriesList: [...action.countries],
        countriesCodes,
        countriesCodesMap,
        countriesDialCodes,
        loaded: true,
        loading: false
      };
    }
    case COUNTRY.LOAD_LIST_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    default:
      return state;
  }
};
