import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Cookie } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';

import { PACKAGES } from '../actions/packages';

const authKey = Cookie.get("AUTH_TOKEN");

const headers = [
  {
    key: 'Authorization',
    value: `bearer ${authKey}`
  }
];

function* addEditPackage(action) {
  const apiObj = new API();
  const { packageObj } = action;
  let res;

  if (packageObj.BundleKey !== null) {
    res = yield apiObj.put(
      `${CoustomUrls.package}/${packageObj.BundleKey}`,
      packageObj,
      headers,
      false
    );
  } else {
    res = yield apiObj.post(CoustomUrls.package, packageObj, headers, false);
  }

  if (STATUS.isSuccess(res.status)) {
    yield put({ type: PACKAGES.ADD_EDIT.DONE });
  } else {
    yield put({ type: PACKAGES.ADD_EDIT.FAILED });
  }
}

function* deletePackage(action) {
  const apiObj = new API();
  const res = yield apiObj.delete(`${CoustomUrls.package}/${action.packageKey}`, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: PACKAGES.DELETE.DONE });
  } else {
    yield put({ type: PACKAGES.DELETE.FAILED });
  }
}

function* getAllPackages() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.package, undefined, false);
  if (STATUS.isSuccess(res.status)) {
    const packages = res.data.filter(packageObj => packageObj.IsDeleted !== true);
    yield put({ type: PACKAGES.FETCH.DONE, packages });
  } else {
    yield put({ type: PACKAGES.FETCH.FAILED });
  }
}

function* packagesSaga() {
  yield takeEvery(PACKAGES.ADD_EDIT.LOAD, addEditPackage);
  yield takeEvery(PACKAGES.FETCH.LOAD, getAllPackages);
  yield takeEvery(PACKAGES.DELETE.LOAD, deletePackage);
}

export default packagesSaga;
