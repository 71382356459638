import { PACKAGES } from '../actions/packages';

const initialState = {
  packages: {
    list: [],
    state: undefined
  },
  addEditPackage: {
    state: undefined
  },
  deletePackageState: undefined
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case PACKAGES.ADD_EDIT.LOAD:
      return {
        ...state,
        addEditPackage: {
          state: PACKAGES.ADD_EDIT.LOAD
        }
      };
    case PACKAGES.ADD_EDIT.DONE: {
      return {
        ...state,
        addEditPackage: {
          state: PACKAGES.ADD_EDIT.DONE
        }
      };
    }
    case PACKAGES.ADD_EDIT.FAILED:
      return {
        ...state,
        addEditPackage: {
          state: PACKAGES.ADD_EDIT.FAILED
        }
      };
    case PACKAGES.DELETE.LOAD:
      return {
        ...state,
        deletePackageState: PACKAGES.DELETE.LOAD
      };
    case PACKAGES.DELETE.DONE: {
      return {
        ...state,
        deletePackageState: PACKAGES.DELETE.DONE
      };
    }
    case PACKAGES.DELETE.FAILED:
      return {
        ...state,
        deletePackageState: PACKAGES.DELETE.FAILED
      };
    case PACKAGES.FETCH.LOAD:
      return {
        ...state,
        packages: {
          list: [],
          state: PACKAGES.FETCH.LOAD
        }
      };
    case PACKAGES.FETCH.DONE: {
      return {
        ...state,
        packages: {
          list: action.packages,
          state: PACKAGES.FETCH.DONE
        }
      };
    }
    case PACKAGES.FETCH.FAILED:
      return {
        ...state,
        packages: {
          state: PACKAGES.FETCH.FAILED
        }
      };
    default:
      return state;
  }
};
