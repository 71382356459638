import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';

import { BENEFITS } from '../actions/benefits';

function* getBenefits() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.getBenefits, undefined, false);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: BENEFITS.LOAD_DONE, benefits: res.data });
  } else {
    yield put({ type: BENEFITS.LOAD_FAIL });
  }
}

function* benefitsSaga() {
  yield takeEvery(BENEFITS.LOAD, getBenefits);
}

export default benefitsSaga;
