import { PAYMENT } from '../actions/payment';

const initialState = {
  paymentMethods: new Map(),
  loading: false
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case PAYMENT.LOAD:
      return {
        ...state,
        paymentMethods: new Map(),
        loading: true
      };
    case PAYMENT.LOAD_DONE: {
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        loading: false
      };
    }
    case PAYMENT.LOAD_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
