import { BENEFITS } from '../actions/benefits';

const initialState = {
  benefits: [],
  loading: false
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case BENEFITS.LOAD:
      return {
        ...state,
        benefits: [],
        loading: true
      };
    case BENEFITS.LOAD_DONE: {
      return {
        ...state,
        benefits: action.benefits,
        loading: false
      };
    }
    case BENEFITS.LOAD_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
