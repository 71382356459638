import { fork } from 'redux-saga/effects';

import country from './country';
import specialtiesSaga from './specialties';
import paymentSaga from './payment';
import benefitsSaga from './benefits';
import languageSaga from './language';
import packagesSaga from './packages';
import bannersSaga from './banners';

const sagas = [
  country,
  specialtiesSaga,
  paymentSaga,
  benefitsSaga,
  packagesSaga,
  languageSaga,
  bannersSaga
];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
