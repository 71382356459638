import React from 'react';
import ReactDOM from 'react-dom';
import { HttpsRedirection, ErrorBoundary, theme } from '@vezeeta/web-components';
import { ThemeProvider } from 'styled-components';

import App from './views/App';
import registerServiceWorker from './registerServiceWorker';

const imageUrl = `${process.env.REACT_APP_CDN_URL}/assets/maintenance.svg`;

const refreshPage = () => {
  window.location.reload();
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary imageUrl={imageUrl} callbackAction={refreshPage}>
      <HttpsRedirection disableHttps={process.env.REACT_APP_FORCE_HTTPS === 'false'}>
        <App />
      </HttpsRedirection>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
