export const BANNERS = {
  FETCH: {
    LOAD: 'BANNERS_FETCH_LOAD',
    DONE: 'BANNERS_FETCH_DONE',
    FAILED: 'BANNERS_FETCH_FAILED'
  },
  DELETE: {
    LOAD: 'BANNERS_DELETE_LOAD',
    DONE: 'BANNERS_DELETE_DONE',
    FAILED: 'BANNERS_DELETE_FAILED'
  },
  ADD_EDIT: {
    LOAD: 'BANNERS_ADD_EDIT_LOAD',
    DONE: 'BANNERS_ADD_EDIT_DONE',
    FAILED: 'BANNERS_ADD_EDIT_FAILED'
  }
};

export const addEditBanner = bannerObj => ({
  type: BANNERS.ADD_EDIT.LOAD,
  bannerObj
});

export const deletBanner = bannerKey => ({
  type: BANNERS.DELETE.LOAD,
  bannerKey
});

export const loadBanners = () => ({
  type: BANNERS.FETCH.LOAD
});
