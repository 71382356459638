import { BANNERS } from '../actions/banners';

const initialState = {
  banners: {
    list: [],
    state: undefined
  },
  addEditBanner: {
    state: undefined
  },
  deleteBannerState: undefined
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case BANNERS.ADD_EDIT.LOAD:
      return {
        ...state,
        addEditBanner: {
          state: BANNERS.ADD_EDIT.LOAD
        }
      };
    case BANNERS.ADD_EDIT.DONE: {
      return {
        ...state,
        addEditBanner: {
          state: BANNERS.ADD_EDIT.DONE
        }
      };
    }
    case BANNERS.ADD_EDIT.FAILED:
      return {
        ...state,
        addEditBanner: {
          state: BANNERS.ADD_EDIT.FAILED
        }
      };
    case BANNERS.DELETE.LOAD:
      return {
        ...state,
        deleteBannerState: BANNERS.DELETE.LOAD
      };
    case BANNERS.DELETE.DONE: {
      return {
        ...state,
        deleteBannerState: BANNERS.DELETE.DONE
      };
    }
    case BANNERS.DELETE.FAILED:
      return {
        ...state,
        deleteBannerState: BANNERS.DELETE.FAILED
      };
    case BANNERS.FETCH.LOAD:
      return {
        ...state,
        banners: {
          list: [],
          state: BANNERS.FETCH.LOAD
        }
      };
    case BANNERS.FETCH.DONE: {
      return {
        ...state,
        banners: {
          list: action.banners,
          state: BANNERS.FETCH.DONE
        }
      };
    }
    case BANNERS.FETCH.FAILED:
      return {
        ...state,
        banners: {
          state: BANNERS.FETCH.FAILED
        }
      };
    default:
      return state;
  }
};
