export const PACKAGES = {
  FETCH: {
    LOAD: 'PACKAGES_FETCH_LOAD',
    DONE: 'PACKAGES_FETCH_DONE',
    FAILED: 'PACKAGES_FETCH_FAILED'
  },
  DELETE: {
    LOAD: 'PACKAGES_DELETE_LOAD',
    DONE: 'PACKAGES_DELETE_DONE',
    FAILED: 'PACKAGES_DELETE_FAILED'
  },
  ADD_EDIT: {
    LOAD: 'PACKAGES_ADD_EDIT_LOAD',
    DONE: 'PACKAGES_ADD_EDIT_DONE',
    FAILED: 'PACKAGES_ADD_EDIT_FAILED'
  }
};

export const addEditPackage = packageObj => ({
  type: PACKAGES.ADD_EDIT.LOAD,
  packageObj
});

export const deletPackage = packageKey => ({
  type: PACKAGES.DELETE.LOAD,
  packageKey
});

export const loadPackages = () => ({
  type: PACKAGES.FETCH.LOAD
});
