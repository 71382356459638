import { combineReducers } from 'redux';

import country from './country';
import specialties from './specialties';
import payment from './payment';
import benefits from './benefits';
import language from './language';
import packages from './packages';
import banners from './banners';

export default combineReducers({
  country,
  specialties,
  payment,
  benefits,
  language,
  packages,
  banners
});
