import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';

import { PAYMENT } from '../actions/payment';

function* getPaymentMethods() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.getPaymentMethods, undefined, false);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: PAYMENT.LOAD_DONE, paymentMethods: res.data });
  } else {
    yield put({ type: PAYMENT.LOAD_FAIL });
  }
}

function* paymentSaga() {
  yield takeEvery(PAYMENT.LOAD, getPaymentMethods);
}

export default paymentSaga;
