import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';

import { LANGUAGE } from '../actions/language';

function* getLanguages() {
  const api = new API();
  const res = yield api.get(CoustomUrls.getLanguages, undefined, false);
  if (STATUS.isSuccess(res.status)) {
    yield put({
      type: LANGUAGE.LOAD_DONE,
      languages: res.data
    });
  } else {
    yield put({
      type: LANGUAGE.LOAD_FAILED
    });
  }
}

export default function* language() {
  yield takeEvery(LANGUAGE.LOAD, getLanguages);
}
