import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Cookie } from '@vezeeta/web-utils';
import CoustomUrls from '../../urls';

import { BANNERS } from '../actions/banners';

const authKey = Cookie.get("AUTH_TOKEN");

const headers = [
  {
    key: 'Authorization',
    value: `bearer ${authKey}`
  }
];

function* addEditBanner(action) {
  const apiObj = new API();
  const { bannerObj } = action;
  debugger;
  let res;

  if (bannerObj.BannerKey !== null) {
    res = yield apiObj.put(
      `${CoustomUrls.banner}/${bannerObj.BannerKey}`,
      bannerObj,
      headers,
      false
    );
  } else {
    res = yield apiObj.post(CoustomUrls.banner, bannerObj, headers, false);
  }
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: BANNERS.ADD_EDIT.DONE });
  } else {
    yield put({ type: BANNERS.ADD_EDIT.FAILED });
  }
}

function* deleteBanner(action) {
  const apiObj = new API();
  const res = yield apiObj.delete(`${CoustomUrls.banner}/${action.bannerKey}`, headers, false);
  if (STATUS.isSuccess(res.status)) {
    yield put({ type: BANNERS.DELETE.DONE });
  } else {
    yield put({ type: BANNERS.DELETE.FAILED });
  }
}

function* getAllBanners() {
  const apiObj = new API();
  const res = yield apiObj.get(CoustomUrls.banner, undefined, false);
  if (STATUS.isSuccess(res.status)) {
    const banners = res.data.sort((a, b) => (a.DisplayOrder > b.DisplayOrder ? 1 : -1));
    yield put({ type: BANNERS.FETCH.DONE, banners });
  } else {
    yield put({ type: BANNERS.FETCH.FAILED });
  }
}

function* bannersSaga() {
  yield takeEvery(BANNERS.ADD_EDIT.LOAD, addEditBanner);
  yield takeEvery(BANNERS.FETCH.LOAD, getAllBanners);
  yield takeEvery(BANNERS.DELETE.LOAD, deleteBanner);
}

export default bannersSaga;
