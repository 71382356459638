import React, { PureComponent } from 'react';
import { Cookie, decodeJwtToken } from '@vezeeta/web-utils';
import { Header, DropDown, UserPreview, Image, COLORS } from '@vezeeta/web-components';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie'

import IconsStore from './IconsStore';

class CustomHeader extends PureComponent {
  onLogout = () => {
    let cookiesArr = document.cookie.split(';')
    cookiesArr.forEach(cookie => {
     let cookieName = cookie.split("=")[0]
      Cookies.remove(cookieName);
    });
    window.location.reload(true);
  };

  render() {
    const token = Cookies.get("AUTH_TOKEN");
    let headerItems = null;
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/' &&
      token !== undefined &&
      token !== null
    ) {
      const data = decodeJwtToken(token).payLoad;
      const userDropDown = [
        {
          component: (
            <UserPreview
              name={data.Name}
              email={data.Email}
              photo={data.Image}
              fallbackPhoto={`${process.env.REACT_APP_CDN_URL}/assets/user-placeholder.png`}
            />
          )
        },
        {
          isDivider: true
        },
        {
          icon: IconsStore.getIcon('logout'),
          text: 'Logout',
          onClick: this.onLogout
        }
      ];

      headerItems = (
        <DropDown
          component={
            <Image
              src={null}
              alt={data.Name}
              name={data.Name}
              radius={30}
              fallback={`${process.env.REACT_APP_CDN_URL}/assets/user-placeholder.png`}
              bgColor={COLORS.WHITE}
              textColor={COLORS.HELP_TEXT}
            />
          }
          items={userDropDown}
          arrowColor="#fff"
        />
      );
    }

    return (
      <Header
        items={headerItems}
        onLogoClick={() => {
          window.location.pathname = '/login';
        }}
        logoUrl={`${process.env.REACT_APP_CDN_URL}/assets/logo.png`}
        px="14%"
      />
    );
  }
}

export default withRouter(CustomHeader);
