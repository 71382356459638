export const COUNTRY = {
  LOAD: 'COUNTRY_LOAD',
  LOAD_LIST: 'COUNTRY_LOAD_LIST',
  LOAD_LIST_DONE: 'COUNTRY_LOAD_LIST_DONE',
  LOAD_DONE: 'COUNTRY_LOAD_DONE',
  LOAD_LIST_FAIL: 'COUNTRY_LOAD_LIST_FAIL',
  LOAD_FAIL: 'COUNTRY_LOAD_FAIL'
};

export const loadCountry = () => ({
  type: COUNTRY.LOAD
});

export const loadCountryList = () => ({
  type: COUNTRY.LOAD_LIST
});

export const loadCountryDone = countries => ({
  type: COUNTRY.LOAD_DONE,
  countries
});

export const loadCountryListDone = countries => ({
  type: COUNTRY.LOAD_LIST_DONE,
  countries
});

export const loadCountryFail = err => ({
  type: COUNTRY.LOAD_FAIL,
  err
});

export const loadCountryListFail = err => ({
  type: COUNTRY.LOAD_LIST_FAIL,
  err
});
